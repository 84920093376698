/**
 * toBoolean
 *
 * @remarks Converts a string/number value to a true boolean. 1 or 'true' = true, 0 or 'false' = false
 *
 * @param value The value to convert to a boolean
 * @returns boolean
 */
function toBoolean(value) {
  if (!value) return false;
  if (typeof value === 'number') {
    if (value === 1) return true;
    if (value === 0) return false;
    return false;
  }
  if (value.trim().toLocaleLowerCase() === 'false') {
    return false;
  } else if (value.trim().toLocaleLowerCase() === 'true') {
    return true;
  }
  return false;
}

export { toBoolean };
